import { CSSProperties } from 'react'
import styled from 'styled-components'
import { Theme } from '../../common/types'

type ParagraphProps = {
    fontWeight?: keyof Theme['fontWeights']
    fontSize?: CSSProperties['fontSize']
    variant?: 'primary' | 'accent'
    marginBlock?: keyof Theme['space']
}

export const Paragraph = styled.p<ParagraphProps>`
    font-weight: ${(props) =>
        props.theme.fontWeights[props.fontWeight || 'normal']};
    font-size: ${(props) => props.fontSize || 'inherit'};
    color: ${(props) =>
        props.variant == 'accent'
            ? props.theme.color.accent
            : props.theme.color.text};
    margin-block: ${(props) =>
        props.marginBlock ? props.theme.space[props.marginBlock] : '0px'};
`

export const H1 = styled(Paragraph).attrs(() => {
    return {
        as: 'h1',
        fontWeight: 'bold',
        fontSize: '3rem',
    }
})``

export const H2 = styled(Paragraph).attrs(() => {
    return {
        as: 'h2',
        fontWeight: 'bold',
        fontSize: '2rem',
    }
})``
