import { BaseColorScales, ColorScales } from '../common/types'

const gray: ColorScales = {
    100: 'rgb(248,248,248)',
    200: 'rgb(238,238,238)',
    300: 'rgb(161,162,164)',
} as const

export const baseColorScales: BaseColorScales = {
    gray,
} as const
