import { createContext } from 'react'
import { OpeningHoursFormat, TimeMode } from '../../../common/types'
import { themes } from '../../../styles/themes'

export type DataContextType = {
    theme: keyof typeof themes
    data: OpeningHoursFormat
    displayMode: TimeMode
    setDisplayMode: (displayMode: TimeMode) => void
    setTheme: (theme: DataContextType['theme']) => void
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in DataProvider')
}

const initialContext: DataContextType = {
    theme: 'light',
    data: {} as OpeningHoursFormat,
    setTheme: stub,
    setDisplayMode: stub,
    displayMode: 'am/pm',
}

const DataContext = createContext<DataContextType>(initialContext)

export default DataContext
