import { ThemeProvider } from 'styled-components'
import './App.css'
import { getTheme } from './styles/themes'
import GlobalStyle from './styles/GlobalStyle'
import OpeningHours from './containers/opening-hours'
import ErrorPage from './components/error-page'
import DataProvider from './components/context/data-context/DataProvider'
import DataContext from './components/context/data-context'

export function App() {
    return (
        <ErrorPage>
            <GlobalStyle />
            <div className="App">
                <div
                    style={{
                        minWidth: '320px',
                        maxWidth: '400px',
                        margin: 'auto',
                    }}
                >
                    <OpeningHours />
                </div>
            </div>
        </ErrorPage>
    )
}

export default function AppWithContext() {
    return (
        <DataProvider>
            <DataContext.Consumer>
                {({ theme }) => {
                    return (
                        <ThemeProvider theme={() => getTheme(theme)}>
                            <App />
                        </ThemeProvider>
                    )
                }}
            </DataContext.Consumer>
        </DataProvider>
    )
}
