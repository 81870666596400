import { FC } from 'react'
import { TimeFormat } from '../../common/types'
import { Paragraph } from '../typography'

type TimeProps = {
    time: TimeFormat
}

const Time: FC<TimeProps> = (props) => {
    const {
        time: { hours, minutes, period },
    } = props

    const paddedString = (stringToBePadded: string) => {
        return stringToBePadded.padStart(2, '0')
    }

    if (hours === null || minutes === null || period === null) {
        return (
            <Paragraph as="span" data-testid="closed">
                Closed
            </Paragraph>
        )
    }

    return (
        <>
            <Paragraph className="time__hours" as="span" data-testid="hours">
                {period !== 'hours' ? hours : paddedString(hours.toString())}
            </Paragraph>
            <Paragraph
                className="time__saperator"
                as="span"
                data-testid="saperator"
            >
                {period === 'hours' ? ':' : ''}
            </Paragraph>
            <Paragraph
                className="time__minutes"
                as="span"
                data-testid="minutes"
            >
                {period === 'hours' ? minutes.toString().padStart(2, '0') : ''}
            </Paragraph>
            <Paragraph className="time__unit" as="span" data-testid="unit">
                {period === 'hours' ? `` : ` ${period}`}
            </Paragraph>
        </>
    )
}

export default Time
