import { useState, FC } from 'react'
import DataContext, { DataContextType } from '.'
import { OpeningHoursFormat, TimeMode } from '../../../common/types'

/* eslint-disable-next-line @typescript-eslint/ban-types*/
const DataProvider: FC<{}> = (props) => {
    const [theme, setTheme] = useState<DataContextType['theme']>('light')

    const [displayMode, setDisplayMode] = useState<TimeMode>('am/pm')

    const [data] = useState<OpeningHoursFormat>({
        monday: [{ type: 'close', value: 3600 }],
        tuesday: [
            { type: 'open', value: 36000 },
            { type: 'close', value: 64800 },
        ],
        wednesday: [],
        thursday: [
            { type: 'open', value: 36000 },
            { type: 'close', value: 39600 },
        ],
        friday: [{ type: 'open', value: 57600 }],
        saturday: [
            { type: 'close', value: 3600 },
            { type: 'open', value: 36000 },
        ],
        sunday: [
            { type: 'close', value: 3600 },
            { type: 'open', value: 43200 },
            { type: 'close', value: 75600 },
            { type: 'open', value: 79200 },
        ],
    })

    return (
        <DataContext.Provider
            value={{ theme, setTheme, data, displayMode, setDisplayMode }}
        >
            {props.children}
        </DataContext.Provider>
    )
}

export default DataProvider
