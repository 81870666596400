import { light } from './themes/light'
import { dark } from './themes/dark'
import { Theme } from '../common/types'

export const themes: Record<string, Theme> = {
    light,
    dark,
}

export function getTheme(whichTheme: keyof typeof themes) {
    if (whichTheme in themes) {
        return themes[whichTheme]
    } else {
        return themes.light
    }
}
