import styled, { StyledProps } from 'styled-components'

type HRProps = {
    variant: 'thin' | 'thick'
}

const HR = styled.hr`
    width: 100%;
    border: none;
    height: ${(props: StyledProps<HRProps>) =>
        props.variant === 'thin' ? '2px' : '2px'};
    background-color: ${(props: StyledProps<HRProps>) =>
        props.variant === 'thin'
            ? props.theme.color.gray[200]
            : props.theme.color.text};
`

export default HR
