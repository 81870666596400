import { FC, Fragment, useContext, useEffect, useState } from 'react'
import { CalculatedWeekOpeningHoursData, Range } from '../../common/types'
import { Flex } from '../../components/box'
import { StyledClock, OpeningHoursCard } from './OpeningHours.styles'
import HR from '../../components/hr'
import {
    calculateWeekDataOriginalToTiming,
    weekDaysOrder,
} from '../../common/utils'
import { Paragraph, H1 } from '../../components/typography/'
import { HiDotsVertical } from 'react-icons/hi'
import TimeRange from '../../components/time/TimeRange'
import DataContext from '../../components/context/data-context'

export interface OpeningHoursProps {}

const OpeningHours: FC<OpeningHoursProps> = () => {
    const { data, displayMode } = useContext(DataContext)

    const getDayToday = () => {
        const dateToday = new Date(Date.now())
        const today = Intl.DateTimeFormat('en-US', { weekday: 'long' })
            .format(dateToday)
            .toLowerCase()
        return today
    }

    const [calculatedWeekTimings, setCalculatedWeekTimings] =
        useState<CalculatedWeekOpeningHoursData>()

    useEffect(() => {
        if (!data) return
        const result = calculateWeekDataOriginalToTiming(data)
        setCalculatedWeekTimings(result)
    }, [data])

    const genTimingsTree = (dayTimings: Array<Range>) => {
        /* Now loop through all the timings*/
        return dayTimings.map((timing, index) => {
            return (
                <Flex
                    key={index}
                    className={`opening-hours__day--time slot_${index + 1}`}
                    justify="flex-end"
                >
                    <TimeRange
                        startTime={timing[0]}
                        endTime={timing[1]}
                        displayMode={displayMode}
                    />
                </Flex>
            )
        })
    }

    return (
        <OpeningHoursCard as="main">
            <Flex
                className="opening-hours"
                justify="center"
                align="center"
                direction="column"
                gap="medium"
            >
                <Flex className="opening-hours__header" direction="row">
                    <Flex
                        className="opening-hours__header--left"
                        direction="row"
                        justify="flex-start"
                        flex="1 0 85%"
                    >
                        <StyledClock size="3rem" />
                        <H1> Opening Hours</H1>
                    </Flex>
                    <Flex
                        className="opening-hours__header--right"
                        direction="row"
                        flex="0 0 15%"
                        align="center"
                        justify="flex-end"
                    >
                        {/*
                         *<HiDotsVertical
                         *    className="opening-hours__header--options"
                         *    size="2rem"
                         * />
                         */}
                    </Flex>
                </Flex>
                <HR variant="thick" />
                {calculatedWeekTimings ? (
                    /* Render week's schedule */
                    weekDaysOrder.map((weekDay) => {
                        const isDayToday = getDayToday() === weekDay
                        const weekDayTimings = calculatedWeekTimings[weekDay]
                        return (
                            <Fragment key={weekDay}>
                                <Flex
                                    key={weekDay}
                                    className="opening-hours__day"
                                    data-testid={`${weekDay}`}
                                    justify="space-between"
                                >
                                    <Flex
                                        className={`opening-hours__day--name ${
                                            isDayToday ? 'today' : ''
                                        }`}
                                        justify="flex-start"
                                    >
                                        <Paragraph fontWeight="bold">
                                            {weekDay}
                                        </Paragraph>
                                        <Paragraph
                                            variant="accent"
                                            fontWeight="bold"
                                            fontSize="1.4rem"
                                        >
                                            {getDayToday() === weekDay
                                                ? 'TODAY'
                                                : ''}
                                        </Paragraph>
                                    </Flex>
                                    <Flex
                                        className={`opening-hours__day--timings ${
                                            weekDayTimings.length === 0
                                                ? 'closed'
                                                : ''
                                        }`}
                                        direction="column"
                                        align="flex-end"
                                        data-testid={`${weekDay}-timings`}
                                    >
                                        {weekDayTimings.length === 0 ? (
                                            <Paragraph>Closed</Paragraph>
                                        ) : (
                                            genTimingsTree(weekDayTimings)
                                        )}
                                    </Flex>
                                </Flex>
                                <HR key={`hr-${weekDay}`} variant="thin" />
                            </Fragment>
                        )
                    })
                ) : (
                    <></>
                )}
            </Flex>
        </OpeningHoursCard>
    )
}

export default OpeningHours
