import { FC, useMemo } from 'react'
import { TimeMode } from '../../common/types'
import { formatTimeFromSeconds } from '../../common/utils'
import Time from '.'

type TimeRangeProps = {
    /* startTime in seconds */
    startTime: number
    /* endTime in seconds */
    endTime: number

    displayMode: TimeMode
}

const TimeRange: FC<TimeRangeProps> = (props) => {
    const { startTime, endTime, displayMode } = props

    const startTimeInMode = useMemo(
        () => formatTimeFromSeconds(startTime, displayMode),
        [startTime, displayMode]
    )

    const endTimeInMode = useMemo(
        () => formatTimeFromSeconds(endTime, displayMode),
        [endTime, displayMode]
    )

    /* should Time display be a saperate components which will handle the preferences to display
     * minutes in both time modes
     * */
    return (
        <>
            <span className="time_range__start">
                <Time time={startTimeInMode} />
            </span>
            <span className="time-range__saperator">{`-`}</span>
            <span className="time-range__end">
                <Time time={endTimeInMode} />
            </span>
        </>
    )
}

export default TimeRange
