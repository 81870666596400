import { Component, createRef, ErrorInfo, PropsWithChildren } from 'react'
import { Flex } from '../box'
import ErrorImage from '../../assets/images/oops_error.jpg'

/* eslint-disable @typescript-eslint/ban-types*/
type ErrorPageProps = PropsWithChildren<{}>

type ErrorPageState = {
    hasError: boolean
    error: Error | undefined
    /*  @typescript-eslint/no-explicit-any*/
    info: ErrorInfo
    isTooltipVisible: boolean
    buttonText: string
}

class ErrorPage extends Component<ErrorPageProps, ErrorPageState> {
    TEXTAREA_ID = 'clipboard'
    state: ErrorPageState = {
        hasError: false,
        error: undefined,
        info: {} as ErrorInfo,
        isTooltipVisible: false,
        buttonText: 'Copy Error',
    }

    collectData = () => {
        return {
            appVersion: process.env.REACT_APP_VERSION,
            userAgent: window.navigator.userAgent,
            languages: window.navigator.languages,
        }
    }

    textAreaInputRef: React.RefObject<HTMLTextAreaElement> = createRef()

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            info,
        })
    }

    componentDidUpdate() {
        const extraInfo = this.collectData()
        if (this.textAreaInputRef.current) {
            this.textAreaInputRef.current.value = JSON.stringify({
                error: this.state.error,
                ...extraInfo,
                ...this.state.info,
            })
        }
    }

    copyToClipboard = () => {
        const createdText = this.textAreaInputRef.current
        if (createdText) {
            createdText.select()
            const status = document.execCommand('copy')
            if (status) {
                this.setState({ ...this.state, buttonText: 'Copied' }, () => {
                    setTimeout(() => {
                        this.setState({
                            ...this.state,
                            buttonText: 'Copy Error',
                        })
                    }, 1000)
                })
            }
        }
    }

    navigateToHome = () => {
        this.setState({ hasError: false })
        window.location.reload()
    }

    render() {
        if (this.state.hasError)
            return (
                <>
                    <textarea
                        id="clipboard"
                        style={{
                            position: 'fixed',
                            opacity: 0,
                            cursor: 'normal',
                        }}
                        ref={this.textAreaInputRef}
                    />
                    <Flex data-testid="error" direction="column" gap="xLarge">
                        <img
                            src={ErrorImage}
                            alt="error image"
                            data-testid="error_image"
                        />
                        <Flex justify="space-around" maxWidth="50%">
                            <button
                                data-testid={'copy_error'}
                                onClick={this.copyToClipboard}
                            >
                                {this.state.buttonText}
                            </button>
                            <button
                                data-testid={'reload_page'}
                                onClick={this.navigateToHome}
                            >
                                Reload Screen
                            </button>
                        </Flex>
                    </Flex>
                </>
            )
        return this.props.children
    }
}

export default ErrorPage
