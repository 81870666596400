import { Theme } from '../../common/types'
import { baseColorScales } from '../common'

export const dark: Theme = {
    color: {
        bgPrimary: 'rgb(0,0,0)',
        text: 'rgba(255,255,255,1)',
        accent: 'rgb(87,204,20)',
        ...baseColorScales,
    },

    fontFace: {
        primary: 'Roboto, sans-serif',
    },

    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    radius: {
        normal: '0.5rem',
    },
    space: {
        xSmall: '0.5rem',
        small: '0.75rem',
        medium: '1rem',
        large: '2.5rem',
        xLarge: '5rem',
        huge: '8rem',
    },
}
