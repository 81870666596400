import { createGlobalStyle, css } from 'styled-components'

import { robotoMixin } from './fonts/roboto'

const cssResetMixin = css`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        /* make browser font size as 10px so that it is easier for rem calculations 
      * in children components
      * */
        font-size: 62.5%;
    }
`

const GlobalStyle = createGlobalStyle`
  ${() => cssResetMixin};
  ${() => robotoMixin};
  body {
    font-family: Roboto, sans-serif ;
    font-display: swap;
    font-size: 1.6rem;
    color: ${(props) => props.theme.color.text};
    padding: ${(props) => props.theme.space.large}
  }
`

export default GlobalStyle
