import {
    DayRange,
    Day,
    Range,
    TimeFormat,
    TimeMode,
    OpeningHoursFormat,
    CalculatedWeekOpeningHoursData,
} from './types'

export const weekDaysOrder: Array<Day> = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]

/**
 *
 *
 * Given 2 arrays,
 * first array contains the opening times of a cafe in a day
 * second array contains closing times of the cafe in a day
 *
 * Calculate the range of timings which represent the opening time of a cafe.
 *
 * arr1 -> [1,2,4,5.5]
 * arr2 -> [2,5,6]
 *
 * result -> [(1,4), (5,6)]
 *
 */

export function calculateRanges(
    openingTimes: Array<number>,
    closingTimes: Array<number>
): DayRange {
    /*
     *
     * Problem: 
     *
        const openingTimes = [8, 10, 16, 18]
        const closingTimes = [11, 12, 13, 17, 19]

        idx  = 0 , range  = [[8,11]], danglingOpentime=null, danglingClosetime = null
        idx = 1 , [10,12] rangeinRange [8,11] ->  range = [[8,11]], danglingOpentime=null, danglingClosetime = null
        idx = 2 , range = [[8,11]], danglingOpentime=16, danglingClosetime = null
        idx = 3, 

     * Assumptions: 
     *   1. We know that dangling open time will be at the end and dangling closing time
     *   will be at start
     *
     *   2. id there are 2 consecetive closing time, without an opening time 
     *
     * PsuedoCode
     *
     * openTimesIdx = 0
     * closeTimesIdx = 0
     *
     * while ( openTimesIdx < openTimesLength || closeTimesIdx < closeTimesLength ){
     *      danglingOpenTime = currentOpenTime
     *
     *      if (closeTimesIdx >= closeTimesLength) {
     *          break;
     *      }
     *
     *      if (openTimesIdx >= openTimesLength){
     *          // if openTimesIdx has increment means there no danglingOpenTime
     *
     *          break;
     *      }
     *
     *      if (danglingOpenTime < currentCloseTime){
     *          // it is a valid pair.
     *          validRange = [danglingOpenTime, currentCloseTime]
     *          if (allRanges.length > 0) {
     *              let newRange = rangeInRange(validRange, allRanges[-10]
     *              if (newRange) {
     *                  allRanges[-1] = newRange
     *              }else{
     *                  allRanges.push(validRange)
     *              }
     *                  
     *          }
     *          // since we have found the pair move ahead
     *          openTimesIdx++;
     *          closeTimesIdx++;
     *          danglingOpenTime = null;
     *      }else{
     *          if (allRanges.length == 0){
     *              danglingClosetime  = danglingClosetime or currentCloseTime
     *          }
     *          // we will not increment openTimesIdx till we have found pair
     *          closeTimesIdx++;
     *          
     *      }
     *
     * }
     *
     * */

    const sortedOpeningTimes = [...openingTimes].sort((a, b) => a - b)
    const sortedClosingTimes = [...closingTimes].sort((a, b) => a - b)
    closingTimes.sort()

    let openTimesIdx = 0
    let closeTimesIdx = 0
    const allRanges: Array<Range> = []

    const openTimesLength = sortedOpeningTimes.length
    const closeTimesLength = sortedClosingTimes.length
    let danglingOpentime = null
    let danglingClosetime = null

    while (openTimesIdx < openTimesLength && closeTimesIdx < closeTimesLength) {
        const currentOpenTime = sortedOpeningTimes[openTimesIdx]
        const currentCloseTime = sortedClosingTimes[closeTimesIdx]

        danglingOpentime = currentOpenTime
        if (danglingOpentime < currentCloseTime) {
            const validRange: Range = [danglingOpentime, currentCloseTime]
            if (allRanges.length > 0) {
                const newRange = rangeInRange(
                    validRange,
                    allRanges[allRanges.length - 1]
                )
                if (newRange) {
                    allRanges[allRanges.length - 1] = newRange
                } else {
                    allRanges.push(validRange)
                }
            } else {
                allRanges.push(validRange)
            }
            // since we have found the pair for dangling OpenTime
            openTimesIdx++
            closeTimesIdx++
            danglingOpentime = null
        } else {
            if (allRanges.length === 0) {
                // Iff we have not found any opening time yet, then only dangling Closing time will be assigned
                // Also if danglingClosetime is already assigned, we will keep it as it is
                danglingClosetime = danglingClosetime || currentCloseTime
            }
            closeTimesIdx++
        }
    }
    if (openTimesIdx < openTimesLength) {
        /* if we have exited the loop because we have exhausted all possible close time values
         *  but not openTime values then make next Opentime value a dangling Time
         * */
        danglingOpentime = sortedOpeningTimes[openTimesIdx]
    }

    if (closeTimesIdx < closeTimesLength && allRanges.length == 0) {
        /*
         * If we have not found any timing any time range
         * there is an element in the close timings which was not checked
         * add it to dangling close time
         * */
        danglingClosetime = sortedClosingTimes[closeTimesIdx]
    }

    return {
        ranges: allRanges,
        danglingOpentime,
        danglingClosetime,
    }
}

export function rangeInRange(
    candidateRange: Range,
    mainRange: Range
): Range | undefined {
    if (candidateRange[0] > mainRange[0] && candidateRange[0] < mainRange[1]) {
        const startTime = Math.min(candidateRange[0], mainRange[0])
        const endTime = Math.min(candidateRange[1], mainRange[1])
        return [startTime, endTime]
    }

    if (candidateRange[1] > mainRange[0] && candidateRange[1] < mainRange[1]) {
        const startTime = Math.min(candidateRange[0], mainRange[0])
        const endTime = Math.min(candidateRange[1], mainRange[1])
        return [startTime, endTime]
    }
    return
}

export function formatTimeFromSeconds(
    timeInSeconds: number,
    format: TimeMode
): TimeFormat {
    const timeinDecimalHours = parseFloat(
        Math.fround(timeInSeconds / 3600).toFixed(2)
    )
    const hours = Math.floor(timeinDecimalHours)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)

    if (format === 'am/pm') {
        return convertTimeHoursToAmPm(hours, minutes)
    }
    return {
        hours,
        minutes,
        period: 'hours',
    }
}

export function convertTimeHoursToAmPm(
    hours: number,
    minutes: number
): TimeFormat {
    // assuming we will call 0 AM as 12 AM
    const hoursInAmPm = hours <= 12 ? (hours === 0 ? 12 : hours) : hours - 12
    return {
        hours: hoursInAmPm,
        minutes,
        period: hours < 12 ? 'AM' : 'PM',
    }
}

export const calculateWeekDataOriginalToTiming = (
    originalOpeningHours: OpeningHoursFormat
): CalculatedWeekOpeningHoursData => {
    const weekDataInRanges: Record<Day, DayRange> = {} as Record<Day, DayRange>

    // convert original format to Ranges
    weekDaysOrder.map((weekDay) => {
        if (weekDay in originalOpeningHours) {
            const weekDayData = originalOpeningHours[weekDay]

            const openTimesOfDay = weekDayData
                .filter((item) => item.type == 'open')
                .map((item) => item.value)
            const closeTimesOfDay = weekDayData
                .filter((item) => item.type == 'close')
                .map((item) => item.value)
            const calculatedRangeForWeekDayData = calculateRanges(
                openTimesOfDay,
                closeTimesOfDay
            )
            weekDataInRanges[weekDay] = calculatedRangeForWeekDayData
        } else {
            weekDataInRanges[weekDay] = {
                ranges: [],
                danglingOpentime: null,
                danglingClosetime: null,
            }
        }
    })

    // calculate and resolve dangling opening and closing times

    const weekDataCalculated: CalculatedWeekOpeningHoursData =
        {} as CalculatedWeekOpeningHoursData
    weekDaysOrder.map((weekDay, idx) => {
        if (weekDay in weekDataInRanges) {
            const currentDayRange = weekDataInRanges[weekDay]
            weekDataCalculated[weekDay] = currentDayRange.ranges
            if (currentDayRange.danglingOpentime !== null) {
                // look for closing time in next day, if it is not there ignore this.
                /* for sunday next should be Monday*/
                const nextDay =
                    idx === weekDaysOrder.length - 1
                        ? weekDaysOrder[0]
                        : weekDaysOrder[idx + 1]
                const nextDayRange = weekDataInRanges[nextDay]
                if (nextDayRange.danglingClosetime !== null) {
                    weekDataCalculated[weekDay].push([
                        currentDayRange.danglingOpentime,
                        nextDayRange.danglingClosetime,
                    ])
                }
            }
        } else {
            // no data available for the day so mark the day closed
            weekDataCalculated[weekDay] = []
        }
    })

    return weekDataCalculated
}
