import { CSSProperties } from 'react'
import styled from 'styled-components'
import { Theme } from '../../common/types'

type BoxProps = {
    flexBasis?: CSSProperties['flexBasis']
    flexGrow?: CSSProperties['flexGrow']
    flexShrink?: CSSProperties['flexShrink']
    flex?: CSSProperties['flex']
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    maxWidth?: CSSProperties['maxWidth']
    maxHeight?: CSSProperties['maxHeight']
}

export const Box = styled.div<BoxProps>`
    background-color: transparent;
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    flex-basis: ${(props) => props.flexBasis};
    flex-grow: ${(props) => props.flexGrow};
    flex-shrink: ${(props) => props.flexBasis};
    flex: ${(props) => props.flex};
    max-width: ${(props) => props.maxWidth};
    max-height: ${(props) => props.maxHeight};
`

type FlexProps = {
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    gap?: keyof Theme['space']
    direction?: CSSProperties['flexDirection']
    wrap?: boolean
}

export const Flex = styled(Box)<FlexProps>`
    display: flex;
    justify-content: ${(props) => props.justify || 'center'};
    align-items: ${(props) => props.align || 'center'};
    gap: ${(props) => props.theme.space[props.gap || 'small']};
    flex-direction: ${(props) => props.direction || 'row'};
    flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
`
