import styled from 'styled-components'
import { Box } from '../box'

const Card = styled(Box)`
    background-color: ${(props) => props.theme.color.bgPrimary};
    border-radius: ${(props) => props.theme.radius.normal};
    padding: ${(props) => {
        return props.theme.space.large
    }};
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.2);
`

export default Card
