import styled from 'styled-components'
import { HiOutlineClock } from 'react-icons/hi'
import Card from '../../components/card'

export const StyledClock = styled(HiOutlineClock)`
    color: ${(props) => props.theme.color.gray[300]};
`

export const OpeningHoursCard = styled(Card)`
    .opening-hours {
        &__header {
            &--options {
                cursor: pointer;
                color: ${(props) => props.theme.color.gray[300]};
            }
        }
        &__day {
            &--name {
                text-transform: capitalize;
                font-weight: ${(props) => props.theme.fontWeights.bold};
            }

            &--timings {
                &.closed {
                    p {
                        color: ${(props) => props.theme.color.gray[300]};
                    }
                }
            }
        }
    }
`
