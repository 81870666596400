import { Theme } from '../../common/types'
import { baseColorScales } from '../common'

export const light: Theme = {
    color: {
        bgPrimary: 'rgb(255,255,255)',
        text: 'rgb(32,33,37)',
        accent: 'rgb(87,204,20)',
        ...baseColorScales,
    },

    fontFace: {
        primary: 'Roboto, sans-serif',
    },

    fontWeights: {
        normal: 400,
        medium: 500,
        bold: 700,
    },
    radius: {
        normal: '1rem',
    },
    space: {
        xSmall: '0.5rem',
        small: '0.75rem',
        medium: '1rem',
        large: '2.5rem',
        xLarge: '5rem',
        huge: '8rem',
    },
}
